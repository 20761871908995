import { useEffect } from 'react';

import { GoogleTagManager } from '@next/third-parties/google';
import { sendGTMEvent } from '@next/third-parties/google';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';

import { PageProps } from '@hultafors/hultafors/types';

export {
  reportWebVitals,
  onRouteChangeComplete,
} from '@hultafors/shared/helpers';

const AppProvider = dynamic(() =>
  import('@hultafors/hultafors/context').then((mod) => mod.AppProvider),
);
const Footer = dynamic(() =>
  import('@hultafors/hultafors/components').then((mod) => mod.Footer),
);
const Fonts = dynamic(() =>
  import('@hultafors/hultafors/components').then((mod) => mod.Fonts),
);
const GlobalStyle = dynamic(() =>
  import('@hultafors/hultafors/components').then((mod) => mod.GlobalStyle),
);
const Nav = dynamic(() =>
  import('@hultafors/hultafors/components').then((mod) => mod.Nav),
);

function CustomApp({ Component, pageProps, router }: AppProps<PageProps>) {
  const {
    global,
    allMenuItems,
    footer,
    settings,
    featureToggle,
    siteLocale,
    ...restProps
  } = pageProps;

  const onRouteChangeComplete = (page: string) => {
    sendGTMEvent({ event: 'pageview', page });
  };

  useEffect(() => {
    if (router.events) {
      onRouteChangeComplete('/');
      router.events.on('routeChangeComplete', onRouteChangeComplete);
    }
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Head>
      <Fonts />
      <GlobalStyle />
      <AppProvider
        global={global}
        footer={footer}
        allMenuItems={allMenuItems}
        settings={settings}
        featureToggle={featureToggle}
        siteLocale={siteLocale}
      >
        <Nav />
        <Component {...restProps} />
        {settings?.market === 'us' && <GoogleTagManager gtmId="GTM-TWBNMM6" />}
        {settings?.market !== 'us' && <GoogleTagManager gtmId="GTM-T8FCVT" />}
        <Footer />
        <div id="portal"></div>
      </AppProvider>
    </>
  );
}

export default CustomApp;
